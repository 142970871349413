import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import request from './utils/request'
import userApi from './api/api'
import util from './utils/util'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import './assets/scss/common.scss'

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';


router.afterEach( () => {
    window.scrollTo(0, 0)
    //百度统计
    // setTimeout(() => {
        
    // }, 0);
})

const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus, {locale, zIndex: 3000})
app.use(request, userApi)
app.use(util)
app.mount('body')






