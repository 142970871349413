// import { App } from 'vue'
//解析拼接查询字符串
const formatQuery = (queryObj) => {
    let arr = []
    for (const key in queryObj) {
        arr.push(`${key}=${queryObj[key]}`)
    }
    return arr.join('&')
}

export default {
    install(app) {
        app.config.globalProperties.$util = {
            formatQuery
        }
    }
}