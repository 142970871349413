import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresAuth: true, title: '首页'},
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home')
  },
  {
    path: '/intro',
    name: 'Intro',
    meta: { requiresAuth: true, title: '公司介绍'},
    component: () => import(/* webpackChunkName: "Intro" */ '../views/Intro')
  },
  {
    path: '/newsCenter',
    name: 'NewsCenter',
    meta: { requiresAuth: true, title: '新闻中心'},
    component: () => import(/* webpackChunkName: "NewsCenter" */ '../views/NewsCenter'),
    children: [
      {path: '', redirect: {name: 'News'}},
      {path: 'news', name: 'News', meta: { requiresAuth: true, title: '新闻总览'}, component: () => import(/* webpackChunkName: "News" */ '../views/News')},
      {path: 'newsDetail', name: 'NewsDetail', meta: { requiresAuth: true, title: '新闻详情'}, component: () => import(/* webpackChunkName: "NewsDetail" */ '../views/NewsDetail')}
    ]
  },
  {
    path: '/projectCenter',
    name: 'ProjectCenter',
    meta: { requiresAuth: true, title: '项目中心'},
    component: () => import(/* webpackChunkName: "ProjectCenter" */ '../views/ProjectCenter'),
    children: [
      {path: '', redirect: {name: 'Project'}},
      {path: 'project', name: 'Project', meta: { requiresAuth: true, title: '公告总览'}, component: () => import(/* webpackChunkName: "Project" */ '../views/Project')},
      {path: 'projectDetail', name: 'ProjectDetail', meta: { requiresAuth: true, title: '公告详情'}, component: () => import(/* webpackChunkName: "ProjectDetail" */ '../views/ProjectDetail')}
    ]
  },
  {
    path: '/bidding',
    name: 'Bidding',
    meta: { requiresAuth: true, title: '竞价中心'},
    component: () => import(/* webpackChunkName: "Bidding" */ '../views/Bidding')
  },
  {
    path: '/recruit',
    name: 'Recruit',
    meta: { requiresAuth: true, title: '招贤纳士'},
    component: () => import(/* webpackChunkName: "Recruit" */ '../views/Recruit')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
