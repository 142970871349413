
class Core {
    http = null
    baseUrl = '/Yongcheng'
    withoutBaseUrl = false
    constructor(requestor, basePath) {
        this.http = requestor
        this.baseUrl = basePath ? (this.baseUrl + basePath) : this.baseUrl
    }
    withoutBase() {
        this.withoutBaseUrl = true
        return this
    }
    get(url, data) {
        return this.fetch('get', url, data)
    }
    post(url, data) {
        return this.fetch('post', url, data)
    }
    put(url, data) {
        return this.fetch('put', url, data)
    }
    delete(url, data) {
        return this.fetch('delete', url, data)
    }
    fetch(method, url = '', data) {
        url = this.withoutBaseUrl ? url : (this.baseUrl + url)
        this.withoutBaseUrl = false
        if(!data) {
            return this.http.request({ method, url})
        } else {
            return this.http.request({ method, url, data})
        }
    }
    urlEncode(query, path) {
        let parsedQuery = this.formatQuery(query)
        return path ? `${path}?${parsedQuery}` : `?${parsedQuery}`
    }
    formatQuery(query) {
        let arr = []
        for (const key in query) {
            if(!query[key]) continue
            arr.push(`${key}=${query[key]}`)
        }
        return arr.join('&')
    }
}

export default Core