import axios from 'axios'
import config from '@/api/config'
// import App from 'vue'

const install = function(app, useApi) {
    
    const instance = axios.create({
        baseURL: config.domain,
        timeout: 300000,
        withCredentials: true
    })

    //请求拦截处理
    instance.interceptors.request.use(
        config => {
            if(config.debug) {
                console.log('request', config);
            }
            return config
        }, 
        error => {
            return Promise.reject(error)
        }
    )

    //响应拦截处理
    instance.interceptors.response.use(
        response => {
            if(config.debug) {
                console.log('response', response);
            }
            return checkCode(response.data)
        },
        error => {
            app.config.globalProperties.$message({
                message: error.response.data.msg || error.message,
                type: 'error'
            })
            return Promise.reject(error.response.data || error.message)
        }
    )

    function checkCode(resp){
        if(resp.code >= 300 && resp.code < 400){
            let url = resp.redirect;
            if(resp.msg){
                url = resp.baseUrl + '/Notice?msg='+resp.msg;
                if(resp.redirect){
                    url += '&redirect='+resp.redirect;
                }
            }
            window.location = url;
        }else if(resp.code != 0){
            app.config.globalProperties.$message({
                message:resp.msg,
                type:resp.type || 'warning'
            });
        }
        return resp;
    }
    
    app.config.globalProperties.$request = instance;

    app.config.globalProperties.$api = useApi(instance);
}

export default {
    install
}