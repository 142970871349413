import ArticleAPI from "./http/article";
import ImageAPI from "./http/image";
import IndexAPI from "./http/index";
import FeedbackAPI from "./http/feedback";
import JobAPI from "./http/job";
import AnnouncementAPI from "./http/announcement";


class API {
    article
    image
    index
    feedback
    job
    announcement
    constructor(requestor) {
        this.article = new ArticleAPI(requestor)
        this.image = new ImageAPI(requestor)
        this.index = new IndexAPI(requestor)
        this.feedback = new FeedbackAPI(requestor)
        this.job = new JobAPI(requestor)
        this.announcement = new AnnouncementAPI(requestor)
    }

}

export default (requestor) => {
    return new API(requestor)
}



