import Core from './core'

class AnnouncementAPI extends Core {
    constructor(requestor) {
        super(requestor, '/Announcement')
    }
    getLatest() {
        const basePath = '/latest'
        return super.get(basePath)
    }
}

export default AnnouncementAPI