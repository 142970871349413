import Core from './core'

class ImageAPI extends Core {
    constructor(requestor) {
        super(requestor, '/Image')
    }
    getImage(type) {
        if(typeof type != 'string') return
        const query = { type }
        let basePath = super.urlEncode(query)
        return super.get(basePath)
    }

}

export default ImageAPI

