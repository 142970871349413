<template>
    <div class="wrap">
        <div class="content-container">
            <div class="upper">
                <div class="brand">
                    <img src="@/assets/image/logo-white.png" alt="logo" class="logo">
                    <div class="name">
                        <img src="@/assets/image/yongcheng-white.png" alt="name">
                        <span>Yongcheng Tedndering Agency</span>
                    </div>
                </div>
                <div class="contact">
                    <p class="name">联系我们</p>
                    <div>
                        <img src="@/assets/image/icon/user.png">
                        <span>张学锋</span>
                    </div>
                    <div>
                        <img src="@/assets/image/icon/mobile.png">
                        <span>15340386581</span>
                    </div>
                    <div>
                        <img src="@/assets/image/icon/position.png">
                        <span>福建省福州市鼓楼区鼓东街道中山路23号</span><br>
                        <span class="span2">省工业品交易中心大楼1101室-1</span>
                    </div>
                </div>
                <div class="links">
                    <p class="name">友情链接</p>
                    <a href="http://www.ccgp.gov.cn/" target="_blank" rel="noopener noreferrer">中国政府采购网</a>
                    <a href="http://www.ccgp-fujian.gov.cn/" target="_blank" rel="noopener noreferrer">福建省政府采购网</a>
                    <a href="http://www.gsxt.gov.cn/index.html" target="_blank" rel="noopener noreferrer">国家企业信用信息公示系统</a>
                    <a href="http://www.ctba.org.cn/" target="_blank" rel="noopener noreferrer">中国招标投标协会</a>
                    <a href="http://www.chinabidding.com.cn/" target="_blank" rel="noopener noreferrer">中国采购与招标网</a>
                </div>
            </div>
            <div class="lower">
                <div>Copyright 2021 .永诚招标代理（xxxx.com）版权所有  福州永诚招标代理有限公司  技术支持：东际网丰</div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'footer-view'
    }
</script>
<style lang="scss" scoped>
    @import "index";
</style>