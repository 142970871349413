import Core from './core'

class FeedbackAPI extends Core {
    constructor(requestor) {
        super(requestor, '/Feedback')
    }
    sendFeedback(data) {
        const basePath = '/add'
        return super.post(basePath, data)
    }
}

export default FeedbackAPI