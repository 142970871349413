import Core from './core'

class ArticleAPI extends Core {
    constructor(requestor) {
        super(requestor, '/Article')
    }
    getList(pid, page) {
        const path = '/getList'
        const query = {
            pid,
            page,
            limit: 10
        }
        const basePath = super.urlEncode(query, path)
        return super.get(basePath).then(res => {
            res.pageSize = query.limit
            return res
        })
    }
    getArticle(id) {
        const query = { id }
        const basePath = super.urlEncode(query)
        return super.get(basePath)
    }
}

export default ArticleAPI