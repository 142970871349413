import Core from './core'

class JobAPI extends Core {
    constructor(requestor) {
        super(requestor, '/Job')
    }
    getJobList() {
        return super.get()
    }
}

export default JobAPI