import { createStore } from 'vuex'

export default createStore({
    state: {
        newsTabId: NaN,
        announcementTabId: NaN
    },
    mutations: {
        setNewsTabId(state, id) {
            state.newsTabId = id
        },
        setAnnouncementTabId(state, id) {
            state.announcementTabId = id
        }
    }

})