import Core from './core'

class IndexAPI extends Core {
    constructor(requestor) {
        super(requestor, '/Index')
    }
    getTabs() {
        return super.get()
    }
    getHomeData() {
        const basePath = '/Home'
        return super.get(basePath)

    }
}

export default IndexAPI