<template>
    <div class="wrap">
        <div class="content-container">
            <router-link :to="{name: 'Home'}" class="brand">
                <img class="logo" src="@/assets/image/logo.png" alt="logo">
                <div class="name">
                    <img src="@/assets/image/yongcheng.png" alt="name">
                    <span>Yongcheng Tedndering Agency</span>
                </div>
            </router-link>
            <div class="nav">
                <div class="link-wrap">
                    <router-link class="link" :to="{name: 'Home'}">首页</router-link>
                </div>
                <div class="link-wrap">
                    <router-link class="link" :to="{name: 'Intro'}">公司介绍</router-link>
                </div>
                <div class="link-wrap" @mouseenter="newsTabsShow = true" @mouseleave="newsTabsShow = false">
                    <router-link class="link" :to="{name: 'News'}" :class="{on: newsDetail}">新闻中心</router-link>
                    <div v-show="newsTabsShow" class="tabsWrap">
                        <div class="Tabs">
                            <span class="tab" v-for="tab in newsTabs" :key="tab.id" @click="goNewsByTab(tab.id)">{{tab.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="link-wrap" @mouseenter="announcementTabsShow = true" @mouseleave="announcementTabsShow = false">
                    <router-link class="link" :to="{name: 'Project'}" :class="{on: projectDetail}">项目中心</router-link>
                    <div v-show="announcementTabsShow" class="tabsWrap">
                        <div class="Tabs">
                            <span class="tab" v-for="tab in announcementTabs" :key="tab.id" @click="goAnnouncementByTab(tab.id)">{{tab.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="link-wrap">
                    <router-link class="link" :to="{name: 'Bidding'}">竞价中心</router-link>
                </div>
                <div class="link-wrap">
                    <router-link class="link" :to="{name: 'Recruit'}">招贤纳士</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    // getCurrentInstance,
    reactive,
    toRefs,
    inject,
    watch
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
    name: "nav-bar",
    setup() {
        const state = reactive({
            newsDetail: false,
            projectDetail: false,
            newsTabsShow: false,
            announcementTabsShow: false
        })

        const store = useStore()

        const newsTabs = inject('newsTabs')
        const announcementTabs = inject('announcementTabs')

        const route = useRoute()
        const router = useRouter()
        watch(() => route.fullPath, (newPath) => {
            if(newPath.indexOf('/newsCenter/newsDetail') > -1) {
                state.newsDetail = true
            } else {
                state.newsDetail = false
            }
            if(newPath.indexOf('/projectCenter/projectDetail') > -1) {
                state.projectDetail = true
            } else {
                state.projectDetail = false
            }
        })



        const goNewsByTab = (id) => {
            store.commit("setNewsTabId", id)
            router.push({path: '/newsCenter/news'})
        }

        const goAnnouncementByTab = (id) => {
            store.commit("setAnnouncementTabId", id)
            router.push({path: '/projectCenter/project'})
        }

        return {
            ...toRefs(state),
            newsTabs,
            announcementTabs,
            goNewsByTab,
            goAnnouncementByTab
        }
    }

};
</script>
<style lang="scss" scoped>
    @import "index";
</style>