<template>
    <div id="app">
        <NavBar />
        <router-view class="main" />
        <FooterView />
    </div>
</template>
<script>
    import NavBar from '@/components/NavBar'
    import FooterView from '@/components/FooterView'
    import { getCurrentInstance, provide, ref } from 'vue'
    // import { useStore } from 'vuex'
    export default {
        components: {
            NavBar,
            FooterView
        },
        setup() {
            const { proxy } = getCurrentInstance()
            

            const newsTabs = ref([])
            const announcementTabs = ref([])


            provide('newsTabs', newsTabs)
            provide('announcementTabs', announcementTabs)

            
            proxy.$api.index.getTabs().then(res => {
                newsTabs.value = res.types.news
                announcementTabs.value = res.types.announcement
                // store.commit("setNewsTabId", res.types.news)
                // store.commit("setAnnouncementTabId", res.types.announcement)
            })
            

            return {

            }
        }
    };
</script>

<style lang="scss" scoped>
    #app {
        font-size: $fontSize;
        padding-top: 130px;
        background-color: $whiteBg;
        display: flex;
        flex-direction: column;
        height: 100%;
        // overflow-y: scroll;
        .main {
            flex-grow: 1;
        }
    }
</style>
